const getEnv = () => {
    return ({
	API_ENDPOINT_URL: 'https://api.lumina.pics',
	STRIPE_PRODUCT: {
	    MONTHLY_SUB_ID: 'price_1JcnLcBQz85GNDYkwOWedPsp',
	    ANNUAL_SUB_ID: 'price_1JcnLcBQz85GNDYkEQm0Pq9w'
	},
	STRIPE_COUPON: {
	    MONTHLY_DISCOUNT: 'Xp7U7spb',
	    ANNUAL_DISCOUNT: 'USCgPjVo',
	    FREE_FULL_ACCESS: 'NqE0l3Ll'
	},
    });
};

export const env = getEnv();
